




















import { Effect } from "@/model/menu";
import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;

export default Vue.component("CategoryGroup", {
  components: {
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QLink: () => import("../../components/elements/QLink.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  data() {
    return {
      category_group_id: this.$route.params.category_group_id,
      isLoading: false,
    };
  },
  beforeRouteUpdate (to, from, next) {
   this.category_group_id = to.params.category_group_id;
    next();
  },
  computed: {
    categoryGroups(){
      let result = [];
      _.forEach(store.state.categoryGroups, (category_group) => {
        if (!category_group.is_display_end_user || !this.hasValidCategory(category_group)) {
          return;
        }

        if (
          (category_group.id == this.category_group_id && category_group.category_id)
          || category_group.parent_id == this.category_group_id
        ) {
          category_group.type = 1;

          if (category_group.category_id) {
            let category = this.getCategory(category_group.category_id)
            category_group.name = category.name;
            category_group.type = category.type;
          }

          category_group.link = `/menu/${category_group.category_id}`;

          if (this.hasChild(category_group.id)) {
            category_group.link = `/category_group/${category_group.id}`;
          }

          result.push(category_group);
        }
      });

      result = _.orderBy(result, ['rank'],['asc']);

      return result;
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    }
  },

  methods: {
    getCategory(id){
      return _.find(store.state.categories, (category) => category.id === id);
    },
    hasChild(id){
      let result = false;

      let child = _.find(store.state.categoryGroups, (category_group) => category_group.parent_id === id);

      return !!child;
    },
    hasValidCategory(category_group) {
      if (!category_group.is_display_end_user) {
        return false;
      }

      let result = false;

      if (category_group.category_id) {
        let category = this.getCategory(category_group.category_id);

        if (category) {
          result = true;
        }
      }

      let childs = _.filter(store.state.categoryGroups, (categoryGroup) => categoryGroup.parent_id === category_group.id);

      _.forEach(childs, (child) => {
        result = result ? result : this.hasValidCategory(child);
      });

      return result;
    },
    toCatStr(catId: number) {
      switch (catId) {
        case 1:
          return "";
        case 2:
          return "takeout";
        case 3:
          return "catering";
      }

      return "";
    },
  }
});
